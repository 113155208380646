.blurbContainer {
    display: inline-flex;
    background-color: #FEFEFE;
    width: 60%;
    height: 60vh;
    margin-left: 10%;
    margin-top: 3%;
}

.blurb {
    letter-spacing: 2px;
    text-align: center;
    margin: 2% 6%;
}

.blurbText {
    font-weight: 500;
    line-height: 2.2;
    color: #1b1b1b;
}

.home-content {
    display: flex;
    width: 100%;
    height: 100%;
}

.string {
    width: 90%;
    height: 20vh;
}