.play {
    display: block;
    -webkit-tap-highlight-color: transparent;
}

.play-button {
    display: block;
    border:none;
    background-color:transparent;
    outline:none;
 }

 @media screen and (max-width: 1024px) {
     .play {
         margin: 0;
         position: absolute;
         z-index: 10;
         top: 50%;
         left: 50%;
         transform: translate(-50%,-50%);
     }
 }