.container {
    display: inline-flex;
    background-color: #FEFEFE;
    border-radius: 6px;
    width: 60%;
    height: 60vh;
    margin-left: 10%;
    margin-top: 3%;
    overflow-y: auto;
    box-shadow: 0 2px 20px 2px;
}