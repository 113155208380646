.player {
    display: flex;
    width: 100%;
    height: 45%;
    justify-content: center;
    position: relative;
    z-index: 2;
    margin-top: 5%;
}

.backdrop {
    display: flex;
    position: relative;
    width: 75%;
    z-index: 0;
}

@media screen and (min-width: 650px) {
    .player {
        height: 55%;
    }
}