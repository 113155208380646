.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 10vh;
    
}

.header {
    margin: 10% 0;
    color: red;
}

.errorText {
    font-family: Bangla MN, Helvetica, sans-serif;
    color: #1B1B1B;
    display: flex;
    width: 75%;
    font-size: 4vw;
    text-align: center;
    line-height: 2;
}