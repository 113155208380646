.container {
    width: 32%;
    height: 0;
    background-color: #49484D;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    padding-inline-start: 0px;
    transition: height 0.5s ease-in-out;
}

.active {
    width: 32%;
    height: 40vh;
    background-color: #49484D;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    padding-inline-start: 0px;
    transition: 0.5s ease-in-out;
    position: relative;
}


.spacer {
    height: 1px;
    background-color: silver;
    width: 100%;
}

.navText {
    font-family: Bangla MN, Helvetica, sans-serif;
    list-style: none;
    display: flex;
    margin: 0;
    color: white;
    letter-spacing: 2px;
}