.item {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 30vh;
    background-color: #f4f4f4;
    box-shadow: 2px 3px 6px 1px rgba(10, 10, 10, 0.8);
    border-radius: 5px;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    margin-bottom: 12%;
    margin-top: 5%;
    align-items: center;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transform-origin: bottom;

}

.itemLink {
    display: flex;
    width: 100%;
}

.itemBox {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100vh;
}

.imgContainer {
    display: flex;
    width: 90%;
    height: 30vh;
}

.image {
    width: 100%;
    height: 100%;
}

.contentContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}

.itemTitle {
    display: flex;
    font-size: 5vw;
    font-family: Bangla MN, Helvetica, sans-serif;
    width: fit-content;
    font-weight: 600;
    margin-left: 4vw;
    margin-bottom: 2vw;
    letter-spacing: 3px;
}

.itemText {
    display: flex;
    font-family: Bangla MN, Helvetica, sans-serif;
    font-size: 3vw;
    width: 40vw;
    line-height: 1.6;
    margin-left: 4vw;
    letter-spacing: 1.5px;
}

@media screen and (orientation:landscape) and (max-width: 1024px) {
    .itemTitle {
        font-size: 5vh;
    }

    .itemText {
        font-size: 3vh;
    }
}