.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding-bottom: 10vh;
    
}

.header {
    margin: 10% 0;
}

.drone {
    display: flex;
    height: 60vh;
    width: 100%;
    justify-content: space-between;
    margin-top: 10%;
    margin-bottom: -5%;
    flex-direction: column;
    align-items: center;
}