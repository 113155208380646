.container {
    display: inline-flex;
    align-items: center;
    width: 60%;
    height: 60vh;
    margin-left: 10%;
    margin-top: 3%;
}

.home-content {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
}