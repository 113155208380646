.container {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        overflow: auto;
    
}

.heading {
    margin: 3% auto;
    font-size: 6vh;
    letter-spacing: 3px;
}