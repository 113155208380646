.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 10vh;
}

.header {
    margin: 10% 0;
}