.container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 5%;
    text-align: center;
}

.home-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blurb {
    letter-spacing: 2px;
    text-align: center;
    margin: 2% 6%;
}

.blurbText {
    font-weight: 500;
    line-height: 2.2;
    color: #1b1b1b;
}

.topText {
    font-weight: 500;
    line-height: 2.2;
    color: #1b1b1b;
    font-size: 3vh;
}

.bottomText {
    font-weight: 500;
    line-height: 2.2;
    color: #1b1b1b;
    font-size: 3vh;
}
.string {
    display: flex;
    width: 80%;
    height: 30vh; 
}

.item-enter {
    opacity: 0;
}

.item-enter-active {
  opacity: 1;
  transition: opacity 700ms ease-in;
}

.contentContainer {
    opacity: 0;
    transition-duration: 1s opacity ease;
}

.itemContainer {
    opacity: 0;
    transition-duration: 1s ease;
}

.active {
    opacity: 1;
  transition-duration: 2s;
 
}

.icon {
    z-index: 10;
}

@media screen and (max-width: 576px) {

    .container {
        margin: 0;
    }
    .topText {
        font-size: 5vw;
        line-height: normal;
        
    }

    .bottomText {
        font-size: 5vw;
        line-height: normal;
        /* margin-top: -5%; */
    }

    .string {
        width: 95%;
        height: 25vh;
    }
}