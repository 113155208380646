.item {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 50vh;
    background-color: #f4f4f4;
    box-shadow: 2px 3px 6px 1px rgba(10, 10, 10, 0.8);
    border-radius: 5px;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    margin-bottom: 7%;
    align-items: center;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transform-origin: bottom;

}

.item:hover {
    animation-name: bounce;
    animation-timing-function: ease;
}
@keyframes bounce {
  0%   { transform: translateY(0); }
  50%  { transform: translateY(-5px); }
  100% { transform: translateY(0); }
}

.itemLink {
    display: flex;
    width: 100%;
}

.itemBox {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100vh;
}

.imgContainer {
    display: flex;
    width: 60%;
    height: auto;
}

.image {
    width: 100%;
    height: 100%;
}

.contentContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}

.itemTitle {
    display: flex;
    font-size: 1.8vw;
    width: fit-content;
    font-weight: bold;
    margin-left: 2vw;
}

.itemText {
    display: flex;
    font-size: 1.2vw;
    width: 18vw;
    line-height: 1.6;
    margin-left: 2vw;
    margin-top: 2%;
}