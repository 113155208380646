.container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 12vw;
    height: 8vh;
    cursor: pointer;
    /* transform-origin: 1px; */
    z-index: 4;
    margin: auto 0px;
  }
  
  .bar1 {
    width: 85%;
    height: 0.5vh;
    background-color: #49484D;
    transition: 0.4s;
    vertical-align: middle;
    margin: 6px 0;
  }

  .bar2 {
    width: 49%;
    height: 0.5vh;
    background-color: #49484D;
    transition: 0.6s;
    align-self: flex-end;
    margin: 6px 0;
  }

  .bar3 {
    width: 66%;
    height: 0.5vh;
    background-color: #49484D;
    transition: 0.8s;
    margin: 6px 0;
  }

   .change .bar1 {
    opacity: 0;
  }
  
  .change .bar2 {
    opacity: 0;
  }
  
  .change .bar3 {
    opacity: 0;
  }

  @media screen and (orientation:landscape) and (max-width: 1024px) {
    .container {
      height: 24vh;
    }
    .bar1,
    .bar2,
    .bar3 {
      height: 1vh;
      background-color: #49484D;
    }
  }